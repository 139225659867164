declare const process: {
  NODE_ENV: "dev" | "prod" | "staging";
  IS_PRD: boolean;
  ASSETS_URL: string | undefined;
  CRAFT_URL: string;
  TRANSLATIONS_URL: string;
  CDN_COOKIES_DOMAIN: string;
  AI_TEXT_GENERATION_API_URL: string | undefined;
};

type ConfigType = {
  ENV: "dev" | "prod" | "staging";
  ASSETS_URL: string;
  TRANSLATIONS_URL: string;
  CRAFT_URL: string;
  CDN_COOKIES_DOMAIN: string;
  AI_TEXT_GENERATION_API_URL: string;
  NODE_ENV: string;
  isDev: () => boolean;
  isStaging: () => boolean;
  isProd: () => boolean;
  isPrd: () => boolean;
};

const {
  NODE_ENV,
  IS_PRD,
  ASSETS_URL,
  CRAFT_URL,
  TRANSLATIONS_URL,
  CDN_COOKIES_DOMAIN,
  AI_TEXT_GENERATION_API_URL,
} = process;

export default {
  ENV: NODE_ENV,
  ASSETS_URL: ASSETS_URL ? ASSETS_URL : window.location.origin,
  // Locally on development environment we load local translation files from /public/locales.
  // In production we load translations from from a CDN.
  TRANSLATIONS_URL: TRANSLATIONS_URL ?? `/locales`,
  CRAFT_URL,
  CDN_COOKIES_DOMAIN,
  AI_TEXT_GENERATION_API_URL: AI_TEXT_GENERATION_API_URL ?? `/rest-api/ai/v1/`,
  NODE_ENV,
  isDev: () => NODE_ENV === "dev",
  isStaging: () => NODE_ENV === "staging",
  isProd: () => NODE_ENV === "prod",
  isPrd: () => IS_PRD,
} as ConfigType;

export const endpointPrefixV1 = "/rest-api/v1";

export const getBaseUrl = (): "/" | "/plus" => {
  switch (NODE_ENV) {
    case "prod":
    case "staging":
      return "/plus";
    default:
      return "/";
  }
};
