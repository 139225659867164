import queryKeys from "@constants/queryKeys";
// This is the configuration file for stale time for each query per page/component.
// This is used to determine how long a query should be considered fresh.
const staleTimeConfig = {
  [queryKeys.domainSettings]: 0,
  [queryKeys.reverseTrial.stats]: 0,
  [queryKeys.branches.courses]: 0,
  [queryKeys.courses.groups]: 0,
  [queryKeys.myBranches]: 0,
  [queryKeys.branches.branches]: 0,
  [queryKeys.courses.availableBranches]: 0,
  [queryKeys.courses.groups]: 0,
  [queryKeys.courses.availableGroups]: 0,
  [queryKeys.courses.unenrolledCourseUsers]: 0,
  [queryKeys.groups.group]: 0,
  [queryKeys.myBranches]: 0,
  [queryKeys.groups.availableUsers]: 0,
  [queryKeys.groups.groups]: 0,
  [queryKeys.branches.branches]: 0,
  [queryKeys.notifications.getNotificationItem]: 0,
  [queryKeys.reports.customReport]: 0,
  [queryKeys.userProfile]: 0,
};

export default staleTimeConfig;
