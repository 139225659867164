import { config } from "@config";

export const imagesPath = `${config.ASSETS_URL}/images`;
export const iconsPath = `${config.ASSETS_URL}/icons`;
export const filesPath = `${config.ASSETS_URL}/files`;

const images = {
  badges: {
    set1: `${imagesPath}/badges/set1.png`,
    set2: `${imagesPath}/badges/set2.png`,
    set3: `${imagesPath}/badges/set3.png`,
    set4: `${imagesPath}/badges/set4.png`,
    set5: `${imagesPath}/badges/set5.png`,
    set6: `${imagesPath}/badges/set6.png`,
  },
  banner: `${imagesPath}/default-banner.jpg`,
  converting: `${imagesPath}/converting.png`,
  cover: `${imagesPath}/default_cover_image.png`,
  errors: {
    fetchData: `${imagesPath}/errors/fetch_data.svg`,
    internalServer: `${imagesPath}/errors/internal_server.svg`,
    notFound: `${imagesPath}/errors/not_found.svg`,
  },
  reverseTrial: {
    dashboardImg: `${imagesPath}/reverseTrial/dashboard_img.png`,
    dashboardEnded: `${imagesPath}/reverseTrial/dashboard_ended.png`,
    dashboardEnding: `${imagesPath}/reverseTrial/dashboard_ending.png`,
  },
  signIn: `${imagesPath}/clouds1.svg`,
};

const icons = {
  favicon: `${iconsPath}/default-favicon.png`,
  logo: `${iconsPath}/default-logo.svg`,
};

const files = {
  importSamplesXLS: `${filesPath}/Import-Samples.xls`,
};

export { images, icons, files };
